import {animated} from "@react-spring/three";
import React, {useRef} from "react";
import {Billboard, useGLTF} from "@react-three/drei";
import {useFrame, useLoader} from "@react-three/fiber";
import cursor from '../assets/cursor.png'
import cursorBillboard from '../assets/cursorBillboard.png'

const Cursor = (props) => {
  useGLTF.preload('/assets/models/tree.glb')
  const { scene, nodes, materials } = useGLTF('/assets/models/tree.glb')
  const meshRef = useRef();
  const texture = useLoader(THREE.TextureLoader, cursor)
  const billboard = useLoader(THREE.TextureLoader, cursorBillboard)

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    meshRef.current.rotation.y = a*0.5;
  });

  return(
    <group {...props} >
      <mesh rotation-x={-Math.PI / 2} position={[0, 0.01, 0]}>
        <planeGeometry
          attach='geometry'
          args={[1, 1, 1, 1]}
        />
        <meshStandardMaterial attach="material" map={texture} transparent  />
      </mesh>
      <animated.mesh ref={meshRef} position={[0, 0, 0]} >
        <primitive object={scene} />
      </animated.mesh>
      <Billboard>
        <mesh position={[0, 0.9, 0]}>
          <planeGeometry
            attach='geometry'
            args={[1, 0.625, 1, 1]}
          />
          <meshStandardMaterial attach="material" map={billboard} transparent  />
        </mesh>
      </Billboard>
    </group>
  )
}

export default Cursor