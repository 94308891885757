import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {customAlphabet} from "nanoid";
import json from "./assets/data.json"
import Xr8Loader from "./components/Xr8Loader";
import About from "./components/About";
import useStore from "./store/useStore";

const App = () => {
  const {setUser} = useStore(state => state)
  const location = useLocation();
  const mid = location.pathname.split('/')[1]
  const [check, setCheck] = useState(false)


  // useEffect(async () => {
  //   const nanoid = customAlphabet('1234567890abcdefghijklmopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 8)
  //   let id = await nanoid()
  //   console.log("ID : " + id)
  // }, [])

  useEffect(()=>{
    let uris = [];
    json.data.map((data)=> {
        uris.push(data.uri)
        if (data.uri === mid) {
          setUser(data)
        }
      }
    )
    const checkId = uris.includes(mid)
    setCheck(checkId)
  }, [])

  return (
    <Routes>
      {check &&
        <Route path={"*"} element={<Xr8Loader/>}/>
      }
      {!check &&
        <Route path={"*"} element={<About/>}/>}
    </Routes>
  );
};

export default App;