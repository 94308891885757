import React, {useState, useEffect} from 'react';
import {Button} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Lottie from "react-lottie";
import moment from "moment/moment";
import CloseIcon from '@mui/icons-material/Close';
import { useThree } from '@react-three/fiber'
import * as animationData from "../animations/loading.json";
import useStore from "../store/useStore";
import ThreeAR from "../store/ThreeAR";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const Screenshot = ()=>{

  const [working, setWorking] = useState(false)
  const [preview, setPreview] = useState(false)
  const [previewUrl, setPreviewUrl] = useState("")
  const [previewData, setPreviewData] = useState(null)

  const screenshot = () => {
    setWorking(true)
    setTimeout(()=>{

      const canvases = document.querySelectorAll('canvas')
      const shotCanvas = document.createElement('canvas');
      const shotCtx = shotCanvas.getContext("2d")
      shotCanvas.width = window.innerWidth
      shotCanvas.height = window.innerHeight

      const videoCanvas = canvases[1]
      shotCtx.drawImage(
        videoCanvas,
        0, 0, videoCanvas.width, videoCanvas.height,
        // 0, 0, 100, 100,
        0, 0, shotCanvas.width, shotCanvas.height
      );

      ThreeAR.renderer.render(ThreeAR.scene, ThreeAR.camera)
      const canvasDom = ThreeAR.renderer.domElement
      shotCtx.drawImage(
        canvasDom,
        0, 0, canvasDom.width, canvasDom.height,
        0, 0, shotCanvas.width, shotCanvas.height
      );

      shotCanvas.toBlob((blob) => {
        setPreviewData(blob)
        setPreviewUrl(shotCanvas.toDataURL("image/png"))
        setPreview(true)
        setWorking(false)
      }, "image/png")

    }, 100)
  }

  const share = async () => {
    const fileName = "wishtree_" + moment().format("YYYYMMDD_HHmmss") + ".png"
    const shareData = {
      title: '소망나무',
      text: '우리 함께 서로의 소망을 나누고 응원해요.',
      files: [
        new File([previewData], fileName, {
          type: previewData.type,
        }),
      ],
    }
    try {
      await navigator.share(shareData);
      console.log('Shared successfully');
    } catch (err) {
      console.log(`Error: ${err}`)
    }
  }

  const Preview = () => {
    return (
      <div className={"fixed top-0 left-0 w-full h-full "}>
        <div className={"absolute inset-0 z-10 m-auto rounded-lg border-solid border-8 border-white w-10/12 h-fit "}>
          {/* Close */}
          <div className={"absolute top-2 right-0 h-12 w-12 z-30 "}>
            <Button sx={ { borderRadius: 28 } } className="rounded-circle w-10 h-10 backdrop-blur-md opacity-75 !min-w-0"
                    onClick={() => {
                      setPreview(false)
                    }}>
              <CloseIcon className={"w-8 h-8 -m-2 text-white"}/>
            </Button>
          </div>
          {/* Preview */}
          <img src={previewUrl}/>
        </div>

        {/* Share */}
        <div className={"absolute  bottom-0 h-16 w-full z-30 "}>
          <div className={"mx-auto w-fit"}>

            <div className={"opacity-100"} onClick={share}>
              <div className="absolute bottom-4 right-40 h-12 w-12 bg-white rounded-3xl text-center p-2">
                <ShareIcon htmlColor={"black"} fontSize={"large"}/>
                <div className={"text-xs"}>공유</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  const Working = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div className={"absolute inset-0 z-50"}>
        <div className={"flex justify-center items-center h-full"}>
          <Lottie options={defaultOptions}
                  height={80}
                  width={80}/>
        </div>
      </div>
    )
  }

  const Capture = ()=>{
    return(
      <div className={"opacity-100"} onClick={screenshot}>
        <div className="absolute bottom-4 right-40 h-12 w-12 bg-white rounded-3xl text-center p-2">
          <CameraAltIcon htmlColor={"black"} fontSize={"large"}/>
          <div className={"text-xs"}>사진</div>
        </div>
      </div>
    )
  }

  return(
    <div id={"screenshot"} >
      {!preview && <Capture/>}
      {preview && <Preview/>}
      {working && <Working/>}
    </div>
  )
}

export default Screenshot