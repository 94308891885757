import React from 'react';
import './styles.css'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from "react-router-dom";
import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);

// createRoot(document.getElementById('root')).render(<Demo />)


// var stats = new Stats();
// stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild( stats.dom );
// function animate() {
//   stats.begin();
//   // monitored code goes here
//   stats.end();
//   requestAnimationFrame( animate );
// }
// requestAnimationFrame( animate );