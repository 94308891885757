import {Box, Plane, Sphere} from "@react-three/drei";
import React, {useMemo, useState} from "react";
import {textureFromText} from "../utils/graphics";
import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {getRandomInt} from "../utils/math";
import {useSpring, animated, easings} from "@react-spring/three";
import {Select} from "@react-three/postprocessing";

const redOn = new THREE.Color(7, 0, 0.1)
const blueOn = new THREE.Color(0, 0.5, 7)
const yellowOn = new THREE.Color(4, 4, 0.1)
const greenOn = new THREE.Color(0, 4, 0.1)

const redOff = new THREE.Color(0.5, 0, 0)
const blueOff = new THREE.Color(0, 0.5, 0.7)
const yellowOff = new THREE.Color(0.7, 0.7, 0.1)
const greenOff = new THREE.Color(0, 0.7, 0.1)

const onColors = [redOn, blueOn, yellowOn, greenOn]
const offColors = [redOff, blueOff, yellowOff, greenOff]

const Message = (props) => {
  const {message, position, rotation, font} = props

  const textureSrc = textureFromText(
    message, 512, 170,
    font, 24, 'FIT', 5);
  const texture = useLoader(THREE.TextureLoader, textureSrc)

  const select = getRandomInt(0, onColors.length)
  const on = onColors[select]
  const off = offColors[select]

  const duration = getRandomInt(1500, 2000)
  const delay = getRandomInt(300, 1700)
  const springs = useSpring({
    from: {
      scale: 0.01,
    },
    to: {
      scale: 0.02,
    },
    config: {
      duration: duration,
      delay: delay,
      easing: easings.easeInOutQuart,
    },
    loop: { reverse: true },
  })


  return (
    <group position={position} rotation={rotation}>

      <animated.mesh scale={springs.scale}>
        <sphereGeometry/>
        <meshBasicMaterial color={on} toneMapped={false}  />
      </animated.mesh>


      {/*<Sphere radius={1} scale={0.008} position-y={-0.01}>*/}
      {/*  <meshBasicMaterial color={off} toneMapped={false}/>*/}
      {/*</Sphere>*/}

      {/* Front */}
      <Plane args={[3, 1]} scale={0.2} rotation-z={-Math.PI / 2} position-y={-0.31}>
        <meshStandardMaterial attach="material" map={texture} roughness={1} metalness={0} color={"lightgray"}/>
      </Plane>
      {/* Back */}
      <Plane args={[3, 1]} scale={0.2} rotation-z={-Math.PI / 2} rotation-y={-Math.PI} position-y={-0.31}>
        <meshStandardMaterial attach="material" map={texture} roughness={1} metalness={0} color={"lightgray"}/>
      </Plane>

      <Box scale={[0.001, 4, 0.001]} position-y={2}/>

    </group>
  )
}

export default Message
