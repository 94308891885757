import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import use8thWallScripts from './use8thWallScripts';

const use8thWall = (appKey, canvas) => {
    const areScriptsReady = use8thWallScripts(appKey);
    const [XR8Object, setXR8Object] = useState(null);
    //
    // PC 안내 문구
    //
    let pcInDom = false
    const pcObserver = new MutationObserver(() => {
        if (document.querySelector('.landing8-prompt-section')) {
            if (!pcInDom) {
                document.querySelector('.landing8-prompt-text').innerHTML = '모바일 만 지원합니다.<br/>QR코드를 스캔해 주세요.'
            }
            pcInDom = true
        } else if (pcInDom) {
            pcInDom = false
            pcObserver.disconnect()
        }
    })
    pcObserver.observe(document.body, {childList: true})
    //
    // 모션 센서 권한 요청 문구
    //
    let motionInDom = false
    const motionObserver = new MutationObserver(() => {
        if (document.querySelector('.prompt-box-8w')) {
            if (!motionInDom) {
                document.querySelector('.prompt-box-8w p').innerHTML = 'AR실행을 위해<br/>모션 센서 권한이 필요합니다.'
                document.querySelector('.prompt-button-8w').innerHTML = '취소'
                document.querySelector('.button-primary-8w').innerHTML = '승인'
            }
            motionInDom = true
        } else if (motionInDom) {
            motionInDom = false
            motionObserver.disconnect()
        }
    })
    motionObserver.observe(document.body, {childList: true})
    //
    // 카메라 사용 권한 요청 문구
    //
    let cameraInDom = false
    const cameraObserver = new MutationObserver(() => {
        if (document.querySelector('#loadingContainer')) {
            if (!cameraInDom) {
                document.querySelector('#requestingCameraPermissions').innerHTML = 'AR실행을 위해<br/>카메라 사용 권한이 필요합니다.'
            }
            cameraInDom = true
        } else if (cameraInDom) {
            cameraInDom = false
            cameraObserver.disconnect()
        }
    })
    cameraObserver.observe(document.body, {childList: true})

    // const poweredByImage = document.querySelector(".poweredby-img")
    // if (poweredByImage) {
    //     poweredByImage.src="/assets/images/AR-BG.jpg"
    // }


    useEffect(() => {
        if (!XR8Object && areScriptsReady && canvas) {
            const { XRExtras } = window;

            XRExtras.Loading.showLoading({
                onxrloaded: () => {
                    const { XR8 } = window;
                    window.THREE = THREE;

                    // XR8.xrController().configure({ disableWorldTracking: false });
                    XR8.addCameraPipelineModules([
                        // Existing pipeline modules.
                        XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
                        XR8.Threejs.pipelineModule(),                // Creates a ThreeJS AR Scene.
                        XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
                        XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
                        XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
                        XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
                        LandingPage.pipelineModule(),
                    ]);

                    XR8.run({ canvas });

                    setXR8Object(XR8);
                },
            });
        }
    }, [XR8Object, areScriptsReady, canvas]);

    return XR8Object;
};

export default use8thWall;
