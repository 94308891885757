import React, {useRef} from "react";
import {Sparkles, useGLTF} from '@react-three/drei'
import MessageLoader from "../components/MessageLoader";

const Tree = (props) => {

  useGLTF.preload('/assets/models/SM_EuropeanHornbeam_Forest_07_PP_LOD2_Texture.glb')
  const { scene, nodes, materials } = useGLTF('/assets/models/SM_EuropeanHornbeam_Forest_07_PP_LOD2_Texture.glb')
  const meshRef = useRef();

  // const [
  //   // Decoration
  //   decoAlbedoMap,
  //   decoMaskMap,
  //   decoNormaMap,
  //   // Tileable
  //   tileAlbedoMap,
  //   tileMaskMap,
  //   tileNormaMap,
  //   // Trunk
  //   trunkAlbedoMap,
  //   trunkMaskMap,
  //   trunkNormaMap,
  //   // TwoSided
  //   twoSideAlbedoMap,
  //   twoSideMaskMap,
  //   twoSideNormaMap,
  // ] = useTexture([
  //   // Decoration
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Decoration_Albedo.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Decoration_Mask.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Decoration_Normal.jpg',
  //   // Tileable
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Tileable_Albedo.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Tileable_Mask.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Tileable_Normal.jpg',
  //   // Trunk
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Trunk_02_Albedo.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Trunk_02_Mask.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_Trunk_02_Normal.jpg',
  //   // TwoSided
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_TwoSided_Summer_Albedo.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_TwoSided_Summer_Mask.jpg',
  //   '/assets/textures/T_EuropeanHornbeam/T_EuropeanHornbeam_TwoSided_Summer_Normal.jpg',
  // ])
  //
  // useMemo(() => {
  //   // applyProps(materials.MI_EuropeanHornbeam_Forest_07_Decoration, {
  //   //   map: decoAlbedoMap,color:"white"})
  //   //   // normalMap: decoNormaMap,})
  //   //   // aoMap: decoMaskMap})
  //   //
  //   // applyProps(materials.MI_EuropeanHornbeam_Forest_07_Tileable, {
  //   //   map: tileAlbedoMap,color:"white"})
  //   //   // normalMap: tileNormaMap,})
  //   //   // aoMap: tileMaskMap})
  //   //
  //   // applyProps(materials.MI_EuropeanHornbeam_Forest_07_TwoSided, {
  //   //   map: twoSideAlbedoMap,color:"white"})
  //   //   // normalMap: twoSideNormaMap,
  //   //   // alphaMap: twoSideMaskMap})
  //
  //   // applyProps(materials.MI_EuropeanHornbeam_Trunk_02, {
  //   //   map: trunkAlbedoMap,color:"white"})
  //   //   // normalMap: trunkNormaMap,})
  //   //   // aoMap: trunkMaskMap})
  //
  //   materials.MI_EuropeanHornbeam_Trunk_02 = new THREE.MeshStandardMaterial(
  //     {
  //       map: trunkAlbedoMap,color:"white"
  //     }
  //   )
  //
  // }, [materials])

  return(
    <group {...props} ref={meshRef} >
      <mesh scale={1} receiveShadow={false} >
        <primitive object={scene} />
      </mesh>
      <MessageLoader/>
      <Sparkles count={200} scale={[5, 5, 5]} size={1} speed={1} position-y={5} />
    </group>
  )
}

export default Tree