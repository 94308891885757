import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useFrame, useThree} from "@react-three/fiber";
import {Sphere, useGLTF} from "@react-three/drei";
import useStore from "../store/useStore";
import {generateNormalMap, textureFromText} from "../utils/graphics";
import * as THREE from "three";
import {animated, easings, useSpring} from "@react-spring/three";
import {useDrag} from "@use-gesture/react";
import useFontFaceObserver from "use-font-face-observer";

const Ring = () => {
  const ref = useRef();
  const [loaded, setLoaded] = useState(false)
  const {ring, user} = useStore(state => state)
  const {scene, nodes, materials} = useGLTF('/assets/models/ring_diamond.glb')
  const isFontListLoaded = useFontFaceObserver([
    {
      family: `Nanum Gothic`
    },
    {
      family: `Nanum Pen Script`
    },
    {
      family: `Nanum Myeongjo`
    },
    {
      family: `NanumBarunpen`
    },
  ]);

  let rx = 0
  let ry = 0
  const {size, viewport} = useThree();
  const aspect = size.width / viewport.width;
  const [spring, api] = useSpring(() => ({
    rotation: [0, 0, 0],
    config: {friction: 10}
  }));
  const bind = useDrag(
    ({active, movement: [x, y], offset: [ox, oy], timeStamp, event}) => {
      const tx = (rx + ox)*0.01
      const ty = (ry + oy)*0.01

      console.log("tx: " + tx + ", ty: " +ty )
      api.start({
        rotation: [ty, tx, 0]
      });
      rx = tx
      ry = ty

      return timeStamp;
    },
    {delay: true}
  );

  const generateNormal = async () => {

    console.log("%c generateNormal ", "color: orange")

    const message = user.message
    const font = user.font

    const align = message.length > 15 ? "FIT" : "CENTER"

    const engraving_ao = textureFromText(
      message,
      1024,
      1024,
      font,
      64,
      align,
      5
    );
    const engraving_normal = await generateNormalMap(
      engraving_ao,
      0.002,
      0.002,
      -1,
      'Sobel',
      false,
      false,
      true
    )
    const loader = new THREE.TextureLoader();
    // load a resource
    loader.load(
      // resource URL
      engraving_normal,
      // onLoad callback
      function (texture) {
        materials.ring.metalness = 1
        materials.ring.roughness = 0.13
        materials.ring.normalMap = texture
      },
      // onProgress callback currently not supported
      undefined,
      // onError callback
      function (err) {
        console.error('An error happened.');
      }
    );
  }

  // useEffect(() => {
  //
  //   console.log("%c isFontListLoaded : "+isFontListLoaded, "color: red")
  //
  //   if(isFontListLoaded){
  //     generateNormal()
  //     materials.diamond = new THREE.MeshStandardMaterial()
  //   }
  // }, [materials, user, isFontListLoaded])

  useFrame(({camera}) => {
    // Move mesh to be flush with camera
    ref.current.position.copy(camera.position);
    ref.current.quaternion.copy(camera.quaternion);

    // Apply offset
    ref.current.translateZ(-2);
  });


  useEffect(()=>{
    if(ring && !loaded){
      generateNormal()
      setLoaded(true)
    }
  }, [ring])

  const springs = useSpring({
    positionY: ring ? 0 : -3,
    config: {
      duration: 1000,
      easing: easings.easeInOutQuart,
    },
  })

  return (
    <group ref={ref} >
      <animated.group position-y={springs.positionY}>

        <animated.mesh scale={0.3} {...spring}  >
          <primitive object={scene}/>
        </animated.mesh>

        <Sphere scale={1} {...bind()}>
          <meshBasicMaterial opacity="0" transparent="true" thickness="0" transmission="0" depthTest={true}/>
        </Sphere>
      </animated.group>
    </group>
  )
};

export default Ring;
useGLTF.preload('/assets/models/ring.glb')