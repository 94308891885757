import {EffectComposer, RenderPass, ShaderPass} from "three-stdlib";
import {
  Mesh,
  NearestFilter,
  OrthographicCamera,
  PlaneGeometry,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  Texture,
  UniformsUtils,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";

// textureFromText puzzle
function textureFromText(text, width, height, family, size, align, offset) {

  var canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext('2d');
  // clear color first to prevent severe pixelating
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.font = size + 'px ' + family;
  ctx.fillStyle = 'black';

  var x;
  switch (align) {
    case 'CENTER':
      ctx.textAlign = 'center';
      x = width / 2;
      break;
    case 'LEFT':
      ctx.textAlign = 'left';
      x = offset;
      break;
    case 'RIGHT':
      ctx.textAlign = 'right';
      x = width - offset;
      break;
    case 'FIT':

    function fit(min, max) {
      if (max - min < 1)
        return min;
      var test = min + (max - min) / 2;
      ctx.font = test + 'px ' + family;
      var measured = ctx.measureText(text).width;
      return (measured > width - 2 * offset) ? fit(min, test) : fit(test, max);
    }

      size = fit(0, height);
      x = offset;
      // no fit performed
      if ((height - size) < 1) {
        ctx.textAlign = 'center';
        x = width / 2;
      }
      break;
  }
  var y = height / 2 + size / 4;
  ctx.fillText(text, x, y);
  return canvas.toDataURL();
}


/*
    Converts a pre-loaded image to normal map, returned as new image in Data URL format.
    Parameters:
    {
        width: 256, // pixels
        height: 256, // pixels
        strength: 2.5, // 0.01 to 5
        level: 7.0, // 4 to 10
        blur: 0.0, // -32 to 32
        filter: 'Sobel', // or 'Scharr'
        invertR: false,
        invertG: false,
        invertHeight: false
    }
    Based on NormalMap Online by Christian Petry
    Source: https://github.com/cpetry/NormalMap-Online
    Live: https://cpetry.github.io/NormalMap-Online/
*/

var NORMALGEN = NORMALGEN || {};

NORMALGEN.normalCanvas = null;
NORMALGEN.renderer = null;

NORMALGEN.convert = function (heightImage, params) {

  // init renderer
  var normalCanvas = NORMALGEN.normalCanvas = NORMALGEN.normalCanvas ||
    document.createElement('canvas');
  var renderer = NORMALGEN.renderer = NORMALGEN.renderer ||
    new WebGLRenderer({alpha: false, antialias: true, canvas: normalCanvas});
  renderer.setSize(params.width, params.height);

  // init normal map shader
  var normalShader = NORMALGEN.NormalMapShader;
  var normalUniforms = UniformsUtils.clone(normalShader.uniforms);

  // set width and height
  normalUniforms['dimensions'].value =
    [params.width, params.height, 0];

  // set strength and level
  normalUniforms['dz'].value =
    1.0 / params.strength * (1.0 + Math.pow(2.0, params.level));

  // set filter
  normalUniforms['type'].value = (params.filter == 'Sobel') ? 0 : 1;

  // set inversion flags
  normalUniforms['invertR'].value = params.invertR ? -1 : 1;
  normalUniforms['invertG'].value = params.invertG ? -1 : 1;
  normalUniforms['invertH'].value = params.invertHeight ? -1 : 1;

  // setup texture
  var heightTex = new Texture(heightImage);
  heightTex.minFilter = heightTex.magFilter = NearestFilter;
  heightTex.needsUpdate = true;
  normalUniforms['tHeightMap'].value = heightTex;

  // setup material
  var normalMat = new ShaderMaterial({
    fragmentShader: normalShader.fragmentShader,
    vertexShader: normalShader.vertexShader,
    uniforms: normalUniforms
  });

  // setup mesh
  var geometry = new PlaneGeometry(1, 1, 1, 1);
  var mesh = new Mesh(geometry, normalMat);
  mesh.name = 'NORMALGEN_MESH';

  // setup scene
  var scene = new Scene();
  scene.add(mesh);
  var camera = new OrthographicCamera(1 / -2, 1 / 2, 1 / 2, 1 / -2, 0, 1);
  var normalPass = new RenderPass(scene, camera);

  // blur passes
  var gaussianPassY = new ShaderPass(NORMALGEN.VerticalBlurShader);
  var gaussianPassX = new ShaderPass(NORMALGEN.HorizontalBlurShader);

  // set blur params
  gaussianPassY.uniforms['v'].value = params.blur / params.width / 5;
  gaussianPassX.uniforms['h'].value = params.blur / params.height / 5;

  // setup render target
  var rtParams = {
    minFilter: NearestFilter, magFilter: NearestFilter,
    format: RGBAFormat, stencilBufer: false
  };
  var renderTarget = new WebGLRenderTarget(params.width, params.height, rtParams);

  // setup composer
  var composer = new EffectComposer(renderer, renderTarget);
  composer.setSize(params.width, params.height);
  composer.addPass(normalPass);
  composer.addPass(gaussianPassY);
  composer.addPass(gaussianPassX);

  // draw
  composer.render(1 / 60);

  // retrieve image
  var resultImage = normalCanvas.toDataURL();

  // clean up
  normalMat.dispose();
  renderer.dispose();

  return resultImage;
}


NORMALGEN.NormalMapShader = {
  uniforms: {
    'type': {type: '1i', value: 0},
    'invertR': {type: '1f', value: 1},
    'invertG': {type: '1f', value: 1},
    'invertH': {type: '1f', value: 1},
    'dz': {type: '1f', value: 0},
    'dimensions': {type: 'fv', value: [0, 0, 0]},
    'tHeightMap': {type: 't', value: null}
  },

  vertexShader: [
    'precision mediump float;',
    'varying vec2 vUv;',
    'varying vec2 step;',
    'uniform vec3 dimensions;',
    'void main() {',
    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
    'step = vec2(-1.0 / dimensions.x, -1.0 / dimensions.y);', // - to switch from glsl orientation to my orientation :D
    'vUv = uv;',
    '}'
  ].join('\n'),

  fragmentShader: [
    'precision mediump float;',
    'uniform vec3 dimensions;',
    'varying vec2 vUv;',
    'varying vec2 step;',
    'uniform float dz;',
    'uniform float invertR;',
    'uniform float invertG;',
    'uniform float invertH;',
    'uniform int type;',
    'uniform sampler2D tHeightMap;',

    'void main(void) {',
    '	vec2 tlv = vec2(vUv.x - step.x, vUv.y + step.y );',
    '	vec2 lv  = vec2(vUv.x - step.x, vUv.y 		   );',
    '	vec2 blv = vec2(vUv.x - step.x, vUv.y - step.y);',
    '	vec2 tv  = vec2(vUv.x 		  , vUv.y + step.y );',
    '	vec2 bv  = vec2(vUv.x 		  , vUv.y - step.y);',
    '	vec2 trv = vec2(vUv.x + step.x, vUv.y + step.y );',
    '	vec2 rv  = vec2(vUv.x + step.x, vUv.y 		   );',
    '	vec2 brv = vec2(vUv.x + step.x, vUv.y - step.y);',
    '	tlv = vec2(tlv.x >= 0.0 ? tlv.x : (1.0 + tlv.x), 	tlv.y >= 0.0	? tlv.y : (1.0  + tlv.y));',
    '	tlv = vec2(tlv.x < 1.0  ? tlv.x : (tlv.x - 1.0 ), 	tlv.y < 1.0   	? tlv.y : (tlv.y - 1.0 ));',
    '	lv  = vec2( lv.x >= 0.0 ?  lv.x : (1.0 + lv.x),  	lv.y  >= 0.0 	?  lv.y : (1.0  +  lv.y));',
    '	lv  = vec2( lv.x < 1.0  ?  lv.x : ( lv.x - 1.0 ),   lv.y  < 1.0  	?  lv.y : ( lv.y - 1.0 ));',
    '	blv = vec2(blv.x >= 0.0 ? blv.x : (1.0 + blv.x), 	blv.y >= 0.0 	? blv.y : (1.0  + blv.y));',
    '	blv = vec2(blv.x < 1.0  ? blv.x : (blv.x - 1.0 ), 	blv.y < 1.0 	? blv.y : (blv.y - 1.0 ));',
    '	tv  = vec2( tv.x >= 0.0 ?  tv.x : (1.0 + tv.x),  	tv.y  >= 0.0 	?  tv.y : (1.0  +  tv.y));',
    '	tv  = vec2( tv.x < 1.0  ?  tv.x : ( tv.x - 1.0 ),   tv.y  < 1.0 	?  tv.y : ( tv.y - 1.0 ));',
    '	bv  = vec2( bv.x >= 0.0 ?  bv.x : (1.0 + bv.x),  	bv.y  >= 0.0 	?  bv.y : (1.0  +  bv.y));',
    '	bv  = vec2( bv.x < 1.0  ?  bv.x : ( bv.x - 1.0 ),   bv.y  < 1.0 	?  bv.y : ( bv.y - 1.0 ));',
    '	trv = vec2(trv.x >= 0.0 ? trv.x : (1.0 + trv.x), 	trv.y >= 0.0 	? trv.y : (1.0  + trv.y));',
    '	trv = vec2(trv.x < 1.0  ? trv.x : (trv.x - 1.0 ), 	trv.y < 1.0   	? trv.y : (trv.y - 1.0 ));',
    '	rv  = vec2( rv.x >= 0.0 ?  rv.x : (1.0 + rv.x),  	rv.y  >= 0.0 	?  rv.y : (1.0  +  rv.y));',
    '	rv  = vec2( rv.x < 1.0  ?  rv.x : ( rv.x - 1.0 ),   rv.y  < 1.0   	?  rv.y : ( rv.y - 1.0 ));',
    '	brv = vec2(brv.x >= 0.0 ? brv.x : (1.0 + brv.x), 	brv.y >= 0.0 	? brv.y : (1.0  + brv.y));',
    '	brv = vec2(brv.x < 1.0  ? brv.x : (brv.x - 1.0 ), 	brv.y < 1.0   	? brv.y : (brv.y - 1.0 ));',
    '	float tl = abs(texture2D(tHeightMap, tlv).r);',
    '	float l  = abs(texture2D(tHeightMap, lv ).r);',
    '	float bl = abs(texture2D(tHeightMap, blv).r);',
    '	float t  = abs(texture2D(tHeightMap, tv ).r);',
    '	float b  = abs(texture2D(tHeightMap, bv ).r);',
    '	float tr = abs(texture2D(tHeightMap, trv).r);',
    '	float r  = abs(texture2D(tHeightMap, rv ).r);',
    '	float br = abs(texture2D(tHeightMap, brv).r);',
    '   float dx = 0.0, dy = 0.0;',
    '   if(type == 0){',	// Sobel
    '		dx = tl + l*2.0 + bl - tr - r*2.0 - br;',
    '		dy = tl + t*2.0 + tr - bl - b*2.0 - br;',
    '   }',
    '   else{',				// Scharr
    '		dx = tl*3.0 + l*10.0 + bl*3.0 - tr*3.0 - r*10.0 - br*3.0;',
    '		dy = tl*3.0 + t*10.0 + tr*3.0 - bl*3.0 - b*10.0 - br*3.0;',
    '   }',
    '	vec4 normal = vec4(normalize(vec3(dx * invertR * invertH * 255.0, dy * invertG * invertH * 255.0, dz)), texture2D(tHeightMap, vUv).a);',
    '	gl_FragColor = vec4(normal.xy * 0.5 + 0.5, normal.zw);',
    '}'
  ].join('\n')
};


NORMALGEN.HorizontalBlurShader = {

  uniforms: {

    'tDiffuse': {type: 't', value: null},
    'h': {type: 'f', value: 3.0 / 512.0}

  },

  vertexShader: [

    'varying vec2 vUv;',

    'void main() {',

    'vUv = uv;',
    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}'

  ].join('\n'),

  fragmentShader: [

    'uniform sampler2D tDiffuse;',
    'uniform float h;',

    'varying vec2 vUv;',

    'void main() {',

    'vec4 sum = vec4( 0.0 );',
    'float lef4 = vUv.x - 4.0 * h;',
    'float lef3 = vUv.x - 3.0 * h;',
    'float lef2 = vUv.x - 2.0 * h;',
    'float lef1 = vUv.x - 1.0 * h;',
    'float rig1 = vUv.x + 1.0 * h;',
    'float rig2 = vUv.x + 2.0 * h;',
    'float rig3 = vUv.x + 3.0 * h;',
    'float rig4 = vUv.x + 4.0 * h;',

    'lef4 = lef4 >= 0.0 ? lef4 : (1.0 + lef4);',
    'lef4 = lef4 < 1.0  ? lef4 : (lef4 - 1.0 );',
    'lef3 = lef3 >= 0.0 ? lef3 : (1.0 + lef3);',
    'lef3 = lef3 < 1.0  ? lef3 : (lef3 - 1.0 );',
    'lef2 = lef2 >= 0.0 ? lef2 : (1.0 + lef2);',
    'lef2 = lef2 < 1.0  ? lef2 : (lef2 - 1.0 );',
    'lef1 = lef1 >= 0.0 ? lef1 : (1.0 + lef1);',
    'lef1 = lef1 < 1.0  ? lef1 : (lef1 - 1.0 );',
    'rig1 = rig1 >= 0.0 ? rig1 : (1.0 + rig1);',
    'rig1 = rig1 < 1.0  ? rig1 : (rig1 - 1.0 );',
    'rig2 = rig2 >= 0.0 ? rig2 : (1.0 + rig2);',
    'rig2 = rig2 < 1.0  ? rig2 : (rig2 - 1.0 );',
    'rig3 = rig3 >= 0.0 ? rig3 : (1.0 + rig3);',
    'rig3 = rig3 < 1.0  ? rig3 : (rig3 - 1.0 );',
    'rig4 = rig4 >= 0.0 ? rig4 : (1.0 + rig4);',
    'rig4 = rig4 < 1.0  ? rig4 : (rig4 - 1.0 );',

    'sum += texture2D( tDiffuse, vec2( lef4, vUv.y ) ) * 0.051;',
    'sum += texture2D( tDiffuse, vec2( lef3, vUv.y ) ) * 0.0918;',
    'sum += texture2D( tDiffuse, vec2( lef2, vUv.y ) ) * 0.12245;',
    'sum += texture2D( tDiffuse, vec2( lef1, vUv.y ) ) * 0.1531;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, vUv.y ) ) * 0.1633;',
    'sum += texture2D( tDiffuse, vec2( rig1, vUv.y ) ) * 0.1531;',
    'sum += texture2D( tDiffuse, vec2( rig2, vUv.y ) ) * 0.12245;',
    'sum += texture2D( tDiffuse, vec2( rig3, vUv.y ) ) * 0.0918;',
    'sum += texture2D( tDiffuse, vec2( rig4, vUv.y ) ) * 0.051;',
    'if (h > 0.0){',
    '	vec4 srcValue = texture2D( tDiffuse, vec2( vUv.x, vUv.y ) );',
    '	sum = srcValue + srcValue - sum;',
    '}',
    'gl_FragColor = sum;',
    //'gl_FragColor = vec4(1,0,0,1);',

    '}'

  ].join('\n')

};


NORMALGEN.VerticalBlurShader = {

  uniforms: {

    'tDiffuse': {type: 't', value: null},
    'v': {type: 'f', value: 3.0 / 512.0}

  },

  vertexShader: [

    'varying vec2 vUv;',

    'void main() {',

    'vUv = uv;',
    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}'

  ].join('\n'),

  fragmentShader: [

    'uniform sampler2D tDiffuse;',
    'uniform float v;',

    'varying vec2 vUv;',

    'void main() {',

    'vec4 sum = vec4( 0.0 );',
    'float top4 = vUv.y - 4.0 * v;',
    'float top3 = vUv.y - 3.0 * v;',
    'float top2 = vUv.y - 2.0 * v;',
    'float top1 = vUv.y - 1.0 * v;',
    'float bot1 = vUv.y + 1.0 * v;',
    'float bot2 = vUv.y + 2.0 * v;',
    'float bot3 = vUv.y + 3.0 * v;',
    'float bot4 = vUv.y + 4.0 * v;',

    'top4 = top4 >= 0.0 ? top4 : (1.0 + top4);',
    'top4 = top4 < 1.0  ? top4 : (top4 - 1.0 );',
    'top3 = top3 >= 0.0 ? top3 : (1.0 + top3);',
    'top3 = top3 < 1.0  ? top3 : (top3 - 1.0 );',
    'top2 = top2 >= 0.0 ? top2 : (1.0 + top2);',
    'top2 = top2 < 1.0  ? top2 : (top2 - 1.0 );',
    'top1 = top1 >= 0.0 ? top1 : (1.0 + top1);',
    'top1 = top1 < 1.0  ? top1 : (top1 - 1.0 );',
    'bot1 = bot1 >= 0.0 ? bot1 : (1.0 + bot1);',
    'bot1 = bot1 < 1.0  ? bot1 : (bot1 - 1.0 );',
    'bot2 = bot2 >= 0.0 ? bot2 : (1.0 + bot2);',
    'bot2 = bot2 < 1.0  ? bot2 : (bot2 - 1.0 );',
    'bot3 = bot3 >= 0.0 ? bot3 : (1.0 + bot3);',
    'bot3 = bot3 < 1.0  ? bot3 : (bot3 - 1.0 );',
    'bot4 = bot4 >= 0.0 ? bot4 : (1.0 + bot4);',
    'bot4 = bot4 < 1.0  ? bot4 : (bot4 - 1.0 );',

    'sum += texture2D( tDiffuse, vec2( vUv.x, top4 ) ) * 0.051;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, top3 ) ) * 0.0918;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, top2 ) ) * 0.12245;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, top1 ) ) * 0.1531;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, vUv.y ) ) * 0.1633;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, bot1 ) ) * 0.1531;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, bot2 ) ) * 0.12245;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, bot3 ) ) * 0.0918;',
    'sum += texture2D( tDiffuse, vec2( vUv.x, bot4 ) ) * 0.051;',
    'if (v > 0.0){',
    '	vec4 srcValue = texture2D( tDiffuse, vec2( vUv.x, vUv.y ) );',
    '	sum = srcValue + srcValue - sum;',
    '}',
    'gl_FragColor = sum;',

    '}'

  ].join('\n')
};


// generateNormalMap puzzle
function generateNormalMap(srcURL, strength, level, blur, filter, invertR, invertG, invertHeight) {

  return new Promise(function (resolve, reject) {

    var heightImage = new Image();
    heightImage.src = srcURL;
    heightImage.onload = function () {

      var output = NORMALGEN.convert(heightImage,
        {
          width: heightImage.width,
          height: heightImage.height,
          strength: strength,
          level: level,
          blur: blur,
          filter: filter,
          invertR: invertR,
          invertG: invertG,
          invertHeight: invertHeight
        }
      );
      resolve(output);
    };

    heightImage.onerror = function () {

      var pink = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAAC' +
        'QkWg2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wgNDjoFwUNXbAAAABpJREF' +
        'UKM9jfF52jYEUwMRAIhjVMKph6GgAAOxFAlM1pWHTAAAAAElFTkSuQmCC';

      resolve(pink); // return a pink image instead of failing
    };

  });

}

export {textureFromText, generateNormalMap}