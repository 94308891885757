import json from "../assets/data.json"
import {useEffect} from "react";
import Message from "../primitives/Message";
import {nanoid} from "nanoid";

const getRandom = (min, max) => {
  return Math.random() * (max - min) + min;
}

const MessageLoader = () => {

  const BuildMessage =()=>{
    const temp = []

    json.data.map((d)=>{
      // const pos = [getRandom(-2.5,2.5),getRandom(2,5),getRandom(-2.5,2.5)]
      // console.log("%c "+pos, "color: cyan")
      const rot = [0, getRandom(0,360),0]
      temp.push(<Message font={d.font} position={d.position} rotation={rot} message={d.message} key={nanoid()} />)
    })

    return(temp)
  }

  return(
    <>
      {BuildMessage()}
    </>
  )
}

export default MessageLoader
