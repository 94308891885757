import React, {useEffect, useState} from 'react';
import Component from "./Experience";

const Xr8Loader = () => {
  const [config, setConfig] = useState(null)

  const initComponent = async () => {
    const fetchData = await fetch('config.json');
    const componentProps = await fetchData.json();
    setConfig(componentProps)
  };

  useEffect(()=>{
    initComponent()
  }, [])

  return (
    <>
      {config && <Component
        {...config}
      />}
    </>
  );
};

export default Xr8Loader;