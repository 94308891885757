import * as THREE from 'three';
import {Color, DataTexture, EffectComposer, PerspectiveCamera, Scene, TexturePass, WebGLRenderer,} from 'three-stdlib';

import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {useFrame, useThree} from "@react-three/fiber";
import React, {Suspense, useEffect, useRef, useState} from "react";
import Tree from "../primitives/Tree";
import Cursor from "../primitives/Cursor";
import useStore from "../store/useStore";
import ThreeAR from "../store/ThreeAR";

// unreal bloom configuration
const params = {
  exposure: 1,
  strength: 1.5,
  threshold: 0,
  radius: 0,
}

export const Wishtree = () => {

  const { pin, setPin, ring, setReady } = useStore(state => state)
  const { scene, gl: renderer, camera } = useThree();
  const [tapTarget, setTapTarget] = useState(null);
  const $surface = useRef();
  const cursor = useRef();

  ThreeAR.renderer = renderer
  ThreeAR.scene = scene
  ThreeAR.camera = camera

  let scene3
  let isSetup = true
  let combinePass
  let bloomPass
  const cameraTextureCopyPosition = new THREE.Vector2(0, 0)
  let cameraTexture
  let sceneTarget
  let copyPass

  let width
  let height

  const xrScene = () => {
    return { scene, camera, renderer };
  }

  const raycaster = new THREE.Raycaster()
  const tapPosition = new THREE.Vector2()
  const center = new THREE.Vector2(0, 0)

  let surface  // Transparent surface for raycasting for object placement.

  // Populates some object into an XR scene and sets the initial camera position. The scene and
  // camera come from xr3js, and are only available in the camera loop lifecycle onStart() or later.
  const initXrScene = ({scene, camera, renderer, canvasWidth, canvasHeight}) => {
  }

  const placeObjectTouchHandler = (e) => {
    console.log(e)
    // Call XrController.recenter() when the canvas is tapped with two fingers. This resets the
    // AR camera to the position specified by XrController.updateCameraProjectionMatrix() above.
    if (e.touches.length === 2) {
      XR8.XrController.recenter()
    }

    if (e.touches.length > 2) {
      return
    }

    // If the canvas is tapped with one finger and hits the "surface", spawn an object.
    const {scene, camera} = XR8.Threejs.xrScene()

    // calculate tap position in normalized device coordinates (-1 to +1) for both components.
    tapPosition.x = (e.touches[0].clientX / window.innerWidth) * 2 - 1
    tapPosition.y = -(e.touches[0].clientY / window.innerHeight) * 2 + 1

    // Update the picking ray with the camera and tap position.
    raycaster.setFromCamera(tapPosition, camera)

    // Raycast against the "surface" object.
    const surface = $surface.current
    const intersects = raycaster.intersectObject(surface)
    if (intersects.length === 1 && intersects[0].object === surface) {
      setTapTarget([intersects[0].point.x, 0, intersects[0].point.z])
    }

    // setTapTarget([intersects[0].point.x, 0, intersects[0].point.z])

  }

  const onStart = ({canvas, canvasWidth, canvasHeight}) => {


    const {scene, camera, renderer} = XR8.Threejs.xrScene()  // Get the 3js scene from xr3js.

    console.log("Playground onStart:")
    console.log(scene)

    // prevent scroll/pinch gestures on canvas
    canvas.addEventListener('touchmove', (event) => {
      event.preventDefault()
    })

    // Sync the xr controller's 6DoF position and camera paremeters with our scene.
    XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion,
    })

    setReady(true)
  }

  const onUpdate = ({processCpuResult}) => {
    const realitySource = processCpuResult.reality || processCpuResult.facecontroller

    if (!realitySource) {
      return
    }

    const {rotation, position, intrinsics} = realitySource
    for (let i = 0; i < 16; i++) {
      camera.projectionMatrix.elements[i] = intrinsics[i]
    }

    // Fix for broken raycasting in r103 and higher. Related to:
    //   https://github.com/mrdoob/three.js/pull/15996
    // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
    // the inverse
    if (camera.projectionMatrixInverse) {
      if (camera.projectionMatrixInverse.invert) {
        // THREE 123 preferred version
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert()
      } else {
        // Backwards compatible version
        camera.projectionMatrixInverse.getInverse(camera.projectionMatrix)
      }
    }

    if (rotation) {
      camera.setRotationFromQuaternion(rotation)
    }
    if (position) {
      camera.position.set(position.x, position.y, position.z)
    }
  }

  const device = window.XR8.XrDevice.deviceEstimate()
  const needsPrerenderFinish = device.os === 'iOS' && parseFloat(device.osVersion) >= 15.4

  const onRender = () => {
    renderer.clear()
    renderer.clearDepth()
    if (needsPrerenderFinish) {
      renderer.getContext().finish()
    }
    renderer.render(scene, camera)
  }

  useEffect(() => {
    XR8.addCameraPipelineModule({
      name: 'wishtree',
      onStart,
      onUpdate,
      onRender,
      // onCanvasSizeChange,
      xrScene: xrScene
    });
  }, []);

  const moveCursor = () => {
    raycaster.setFromCamera(center, camera)
    const intersects = raycaster.intersectObject($surface.current)
    if (intersects.length === 1 && intersects[0].object === $surface.current) {
      cursor.current.position.x = intersects[0].point.x
      cursor.current.position.z = intersects[0].point.z
    }
  }

  useFrame(() =>{
    moveCursor()
  });

  const setTree = ()=>{

    // 나무 위치 조절
    raycaster.setFromCamera(center, camera)
    const intersects = raycaster.intersectObject($surface.current)
    if (intersects.length === 1 && intersects[0].object === $surface.current) {
      setTapTarget([intersects[0].point.x, 0, intersects[0].point.z])
    }

    setPin(true)
  }

  return (
    <group>

      <mesh receiveShadow position={[0, 0, 0]} ref={$surface} rotation-x={-Math.PI / 2}>
        <planeGeometry
          attach='geometry'
          args={[100, 100, 1, 1]}
        />
        <shadowMaterial
          opacity={0.3}
        />
      </mesh>

      <group
        visible={!pin&&!ring}
        ref={cursor}
        onClick={(e) => {
          setTree()
        }}
      >
        <Suspense fallback={null}>
          <Cursor />
        </Suspense>
      </group>

      <group
        visible={pin&&!ring}
        position={tapTarget}
      >
        <Suspense fallback={null}>
          <Tree/>
        </Suspense>
      </group>


    </group>
  );


}