import create from 'zustand'

const useStore = create(set => ({
  pin: false,
  setPin: (flag) => set({ pin: flag }),

  ring: false,
  setRing: (flag) => set({ ring: flag }),

  ready: false,
  setReady: (flag) => set({ ready: flag }),

  user: {},
  setUser: (data) => set({ user: data }),
}))

export default useStore