import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import useStore from "../store/useStore";
import Screenshot from "./Screenshot";
import React from "react";

const Controller = () => {
  const {pin, setPin, ring, setRing, ready} = useStore(state => state)

  const resetPin = () => {
    if (pin) setPin(false)
  }

  const toggleRing = () => {
    setRing(!ring)
  }

  const ShowRing = () => {
    return (
      <div className={"opacity-100"} onClick={toggleRing}>
        <div className="absolute bottom-4 left-4 h-12 w-12 bg-white rounded-3xl text-center p-2">
          {ring ?
            <SearchOffIcon htmlColor={"black"} fontSize={"large"}/>
            : <SearchIcon htmlColor={"black"} fontSize={"large"}/>
          }
          <div className={"text-xs"}>반지</div>
        </div>
      </div>
    )
  }


  const ResetButton = () => {
    return (
      <div className={pin ? "opacity-100" : "opacity-30" } onClick={resetPin}>
        <div className="absolute bottom-4 right-4 h-12 w-12 bg-white rounded-3xl text-center p-2">
          <RestartAltIcon htmlColor={"black"} fontSize={"large"}/>
          <div className={"text-xs"}>리셋</div>
        </div>
      </div>
    )
  }


  return (
    <div className={"absolute w-full h-20 left-0 right-0 bottom-0 z-[9999]"}>
      {ready && <ShowRing/>}
      {ready && <ResetButton/>}
      {ready && <Screenshot/>}
    </div>
  )
}
export default Controller