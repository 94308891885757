import React, {Suspense, useEffect} from 'react';
import {Canvas, extend, useThree} from '@react-three/fiber'
import {withLauncher} from './ExperienceLoader';
import {withContext} from './ContextProvider';
import useContext from '../hooks/useContext';
import {Wishtree} from "./Wishtree";
import {Effects, Environment,} from "@react-three/drei";
import {RenderPass, UnrealBloomPass} from "three-stdlib";
import Controller from "./Controller";
import Ring from "./Ring";
import useStore from "../store/useStore";

extend({UnrealBloomPass})
extend({RenderPass})

const Effect = () => {
  const {scene, gl, camera} = useThree();

  console.log(scene)
  console.log(gl)
  console.log(camera)

  return (
    <Effects disableGamma>
      <renderPass
        scene={scene}
        camera={camera}
      />
      <unrealBloomPass
        threshold={1}
        strength={2}
        radius={0.5}/>
    </Effects>
  )
}

const Experience = ({XR8, xr8Config, onComplete, backgroundImage,}) => {
  const {
    updateCtx,
  } = useContext();
  const { ring } = useStore(state => state)

  useEffect(() => {
    if (XR8) {
      updateCtx({XR8});
    }
  }, [XR8, updateCtx]);



  return (
    <>
      <Canvas
        className="arCanvas"
        shadowMap
        updateDefaultCamera={false}
        gl={{toneMappingExposure: 1}}
      >
        <Wishtree/>

        <Suspense fallback={null}>
          <Ring/>
        </Suspense>

        <Suspense fallback={null}>
          <Environment
            frames={Infinity}
            files={"/assets/images/modern_buildings_2_2k.hdr"}
          />
        </Suspense>
      </Canvas>

      <Controller/>
    </>
  )
}

export default withContext(withLauncher(Experience))